<template>
    <div :class="{ whiteTheme: isWhiteTheme }">
        <div v-if="!isLoading">
            <div v-if="customSport === Sport.PADEL" class="min-h-600 min-h-responsive">
              <PadelForm
                @userData = "handleUserData"
              />
            </div>
            <div v-if="customSport === Sport.TENIS" class="min-h-600 min-h-responsive">
              <TenisForm
                @userData = "handleUserData"
              />
            </div>
            <div v-if="customSport === Sport.PICKLEBALL" class="min-h-600 min-h-responsive">
              <PickleForm
                @userData = "handleUserData"
              />
            </div>
            <div v-if="customSport === Sport.SOCCER" class="min-h-600 min-h-responsive">
              <SoccerForm
                @userData = "handleUserData"
              />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Sport } from "@/enum/constants";
import PadelForm from "@/views/levelGenderForms/PadelForm.vue"
import TenisForm from "@/views/levelGenderForms/TenisForm.vue"
import PickleForm from "@/views/levelGenderForms/PickleForm.vue"
import SoccerForm from "@/views/levelGenderForms/SoccerForm.vue"
import CustomerHelper from "@/helpers/customerHelper";
import store from "@/store";
import { Customer } from 'models';
import { AppsHelper } from '@/helpers/appsHelper';

export default defineComponent({
    name: "MatchUpdateUser",
    emits: ["closeGenderLevel"],
    props: {
        customSport: {
            type: Number,
            required: true,
        },
        isWhiteTheme: {
            type: Boolean,
            required:false,
            default: false, 
        },
    },
    components: {
        PadelForm,
        TenisForm,
        PickleForm,
        SoccerForm
    },
    data() {
        return {
            isLoading: true,
            Sport: Sport,
            errorUpdate: false,
            error: null as (unknown | null) 
        };
    },
    mounted(){
        this.isLoading = false;
    },
    methods: {
        async handleUserData(userData : any){
            store.commit("setLoading", true);
            try {
                await CustomerHelper.updateCustomerLevel(this.customSport, userData.level);
            } catch (error) {
                this.errorUpdate = true;
                this.error = error;
            }
            var user =  null as (Customer | null) 
            try {
                user = await CustomerHelper.updateGender(userData.gender);
            } catch (error) {
                this.errorUpdate = true;
                this.error = error;
            }
            if(!this.errorUpdate && user){
                CustomerHelper.updateStorageUser(user);
                const jsonData = {
                    success: true,
                    sport: this.customSport,
                    level: userData.level,
                    gender: userData.gender
                };
                try {
                    AppsHelper.sendToIOS(window, jsonData);
                } catch (error) {
                    console.error("Error sharing ios:", error);
                }
                if (AppsHelper.isAndroidWebView()) {
                    const w = window as any;
                    w.Android.onLevelCompleted(true, userData.level as number, userData.gender as number, this.customSport as number, null)
                }
                store.commit("setLoading", false);
            }else{
                const jsonData = {
                    success: false,
                    error: this.error
                };
                try {
                    AppsHelper.sendToIOS(window, jsonData);
                } catch (error) {
                    console.error("Error sharing ios:", error);
                }
                if (AppsHelper.isAndroidWebView()) {
                    const w = window as any;
                    w.Android.onLevelCompleted(false, 0, 0, 0, this.error)
                }
            }
            this.$emit("closeGenderLevel");
        }
    }
});
</script>
<style scoped>
@media (max-width: 400px) {
    .min-h-responsive {
      min-height: 640px;
    }
}
.whiteTheme {
    background-color: white;
    color: black;
}
</style>