export const Category = {
  BOOKING: 0,
  MATCH: 1,
  ACTIVITY: 2
};

export const Sport = {
  PADEL: 0,
  TENIS: 1,
  PICKLEBALL: 2,
  SOCCER: 3
};

export const PadelType = {
  INDOOR: 0,
  OUTDOOR: 1,
  COVER: 2
};

export const PadelSubType = {
  WALL: 0,
  CRISTAL: 1,
  PANORAMIC: 2
};

export const PadelSize = {
  DOUBLE: 0,
  INDIVIDUAL: 1
};

export const TenisType = {
  INDOOR: 0,
  OUTDOOR: 1,
  COVER: 2
};

export const TenisSubType = {
  CLAY: 0,
  QUICK: 1,
  CEMENT: 2,
  SYNTHETICGRASS: 3,
  GRASS: 4
};

export const TenisSize = {
  DOUBLE: 0,
  INDIVIDUAL: 1,
  MINI: 2
};

export const PickleballType = {
  INDOOR: 0,
  OUTDOOR: 1,
  COVER: 2
};

export const PickleballSubType = {
  ASPHALT: 0,
  PLASTICPRESURE: 1,
  PARQUET: 2,
};

export const PickleballSize = {
  DOUBLE: 0,
  INDIVIDUAL: 1,
  MINI: 2
};

export const SoccerType = {
  INDOOR: 0,
  OUTDOOR: 1,
};

export const SoccerSubtype = {
  CONCRETE: 0,
  WOOD: 1,
  SYNTHETIC: 2,
  ARTIFICIALTURF: 3,
  GRASS: 4,
  DIRTFIELDS: 5
};

export const SoccerSize = {
  FUT3: 0,
  FUT5: 1,
  FUT7: 2,
  FUT11: 3,
  FUTSAL: 4
};



export const SearchType = { EXACT: 0, FLEXIBLE: 1, FULL: 2 };
export const Origin = { WEB: 0, ANDROID: 1, IOS: 2, };
export const PlayersMode = { CREATION: 0, JOIN: 1, RESULTS: 2, CANCELED: 3 };
export const PlayerType = { EMPTY: 0, CUSTOMER: 1, UNKNOWN: 2, NONEXISTS: 3 };
export const Team = { TEAM1: 0, TEAM2: 1 };
export const BookingStatus = { PREBOOKED: 0, BOOKED: 1, FINISHED: 2, CANCELED: 3, TEMP: 4 };
export const BookingType = { BOOKING: 0, MATCH: 1, ACTIVITY: 2, CLASS: 3 };
export const BookingTextStatus = {
  BOOKED: "BOOKED",
  FINISHED: "FINISHED"
};

export const SaleStatus = { PENDING: 0, PARTIALLY_PAID: 1, FULL_PAID: 2 };
export const MAP_URL = "http://www.google.com/maps/place/";

export const Gender = { MALE: 0, FEMALE: 1, MIX: 2, OPEN: 3 };
export const UserGender = { MAN: 0, WOMAN: 1, OTHERS: 2 };

export const PrivacyLabel: { [key: string]: string; } = {
  onlyLikesCanFindMe: "only_likes_can_find_me"
};

//export const DEFAULT_IMAGE = "https://api.taykus.com/images/customers/unknown_player.png";
export const DEFAULT_IMAGE = "/img/whiteCircle.72a58138.svg";
export const UNKNOWN_PLAYER = "unknow_player";
export const GUEST = "guest";
export const DEFAULT_TENANT_IMAGE = "/img/paymentImage.a4e52d3f.png";
export const VOUCHER_OPERATION_ID = "voucherOperation";

export const SportLabel = {
  PADEL: "padel",
  TENIS: "tennis",
  PICKLEBALL: "pickleball",
  SOCCER: "soccer"
};

export const GenderLabel = {
  MALE: "male",
  FEMALE: "female",
  MIX: "mixed",
  OPEN: "open"
};

export const PaymentType = {
  FULL: 0,
  SHARED: 1,
  IN_HOUSE: 2
};

export const BookingViewTabs = { HOME: 0, BOOKING: 1, MATCH: 2, ACTIVITY: 3 };

export const NO_LEVEL = -1;
export const LOW_LEVEL = 0.25;
export const ADD_LEVEL = 0.75;
export const MAX_LEVEL = 7;
export const MIN_LEVEL = 0;
export const MOD_LEVEL = 0.5;
export const LEVEL_STEP = 0.1;

export const GlobalErrors = { ERROR_400: 400, ERROR_500: 500, ERROR_PRIVATE_CLUB: 424, ERROR_402: 402, ERROR_401: 401, ERROR_499: 499, ERROR_404: 404}

export const NetworkError = {
  ABORTED: "ECONNABORTED",
  NET: "Network Error",
  PROXY: "Proxy Error"
};

export const SportIconNames = {
  PADEL: "homeModalPadelIcon",
  TENIS: "homeModalTenisIcon",
  PICKLEBALL: "homeModalPickleIcon",
  SOCCER: "homeModalSoccerIcon"
};

export const MAX_LENGTH_TEXT = 20;

export const FromRouting ={
  SEARCH_RESULTS: "search-results",
  BOOKING: "booking"
}

export const Purpose = {
  BOOKING: 0,
  MATCH: 1
};

export const PaymentProvider = {
  TRANSBANK: "TRANSBANK",
  AZUL: "AZUL"
};

export const FLEXIBLE_HOURS = 3;
export const ACTIVITY_MAX_DESC = 402;

export const USER_STATUS = {
  LOG_IN: "logged_in",
  LOG_OUT: "logged_out"
};

export const appLink = {
  APPSTORE: "https://apps.apple.com/es/app/taykus-padel-tenis-y-m%C3%A1s/id6450102598",
  GOOGLEPLAY: "https://play.google.com/store/apps/details?id=com.taykus.marketplace&hl=es_PY"
}

export const WHITE_THEME_BACKGROUND_COLOR = 'white';

export const availabilityInclude = {
  NEXT: "next",
  OLD: "old",
  ALL: "all"
};